import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const baseUrl = "https://api.cryptomax.expert/api/v1";

  const [allDeposits, setAllDeposits] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [userDeposits, setUserDeposits] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [dLoading, setDLoading] = useState(false);
  const [userDLoading, setUserDLoading] = useState(false);
  const [usersLoading, setSLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(false);
  const adminToken = JSON.parse(sessionStorage.getItem("adminToken"));

  // Get All Deposits
  const getAllDeposits = (token) => {
    setDLoading(true);
    axios
      .get(`${baseUrl}/deposits/admin`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        if (data.status === 200) {
          setAllDeposits(data.data.deposits);
          setDLoading(false);
        }
      })
      .catch((error) => {
        setDLoading(false);
      });
  };

  // Get All Users
  const getAllUsers = (token) => {
    setSLoading(true);
    axios
      .get(`${baseUrl}/users`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((data) => {
        if (data.status === 200) {
          setSLoading(false);
          setAllUsers(data.data.users);
        }
      })
      .catch((error) => {
        setSLoading(false);
      });
  };

  // Get User Deposits
  const getUserDeposits = (id) => {
    setUserDLoading(true);
    axios
      .get(`${baseUrl}/deposits/admin/${id}`, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((data) => {
        if (data.status === 200) {
          setUserDeposits(data.data);
          setUserDLoading(false);
        }
      })
      .catch((error) => {
        setUserDLoading(false);
      });
  };

  // Get User Details
  const getUserDetails = (id) => {
    setUserLoading(true);
    axios
      .get(`${baseUrl}/users/${id.toString()}`, {
        headers: { Authorization: `Bearer ${adminToken}` },
      })
      .then((data) => {
        if (data.status === 200) {
          setUserDetails(data.data.user);
          setUserLoading(false);
        }
      })
      .catch((error) => {
        setUserLoading(false);
      });
  };

  return (
    <AppContext.Provider
      value={{
        baseUrl,
        getAllDeposits,
        allDeposits,
        getAllUsers,
        allUsers,
        userDeposits,
        getUserDeposits,
        getUserDetails,
        userDetails,
        dLoading,
        usersLoading,
        userLoading,
        userDLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
