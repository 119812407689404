import React, { useEffect, useState } from "react";
import "./CreateFoodItems.css";
import { Button, CreateFoodHeader } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateFoodItems = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { baseUrl, getUserDetails, userDetails } = useGlobalContext();
  const adminToken = JSON.parse(sessionStorage.getItem("adminToken"));
  const [wLoading, setWLoading] = useState(false);
  const { register, handleSubmit } = useForm();
  const [loading, setLoading] = useState(false);
  const notify = () => toast.success("User Details Updated");

  const navigateBack = () => {
    navigate(`/user-details/${id}`);
  };

  const updateUserProfitAndProfit = (data) => {
    data.bonus = data.bonus !== "" ? data.bonus : userDetails.bonus;
    data.profits = data.profits !== "" ? data.profits : userDetails.profits;
    data.otp = data.otp !== "" ? data.otp : userDetails.otp;
    data.otpStep = data.otpStep !== "" ? data.otpStep : userDetails.otpStep;
    data.otpMessage =
      data.otpMessage !== "" ? data.otpMessage : userDetails.otpMessage;
    if (data.profits || data.bonus !== "") {
      setLoading(true);
      axios
        .patch(`${baseUrl}/users/${id}`, data, {
          headers: { Authorization: `Bearer ${adminToken}` },
        })
        .then((data) => {
          if (data.status === 200) {
            setLoading(false);
            getUserDetails(id);
            notify();
            setTimeout(() => {
              navigate(`/users`);
            }, 2000);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const activateWithdrawal = () => {
    setWLoading(true);
    axios
      .patch(
        `${baseUrl}/users/${id}`,
        {
          userCanWithdraw: true,
        },
        { headers: { Authorization: `Bearer ${adminToken}` } }
      )
      .then((data) => {
        if (data.status === 200) {
          toast.success("Withdrawal Active");
          setWLoading(false);
          setTimeout(() => {
            navigate("/users");
          }, 2000);
        }
      })
      .catch((error) => {
        setWLoading(false);
      });
  };

  useEffect(() => {
    getUserDetails(id);
  }, []);

  return (
    <section className="add_food_item_container">
      <CreateFoodHeader
        btn={false}
        navigateBack={navigateBack}
        title={"Update User Details"}
      />
      <div className="add_food_item_form">
        <div className="add_food_item_form_item_three">
          <p className="add_food_item_form_labels">Profit</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="Profit"
            {...register("profits")}
            defaultValue={userDetails.profits}
          />
        </div>
        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">Bonus</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="Bonus"
            {...register("bonus")}
            defaultValue={userDetails.bonus}
          />
        </div>
        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">OTP</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="OTP"
            {...register("otp")}
            defaultValue={userDetails.otp}
          />
        </div>
        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">OTP Message</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="OTP Message"
            {...register("otpMessage")}
            defaultValue={userDetails.otpMessage}
          />
        </div>
        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">OTP Step</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="OTP Step"
            {...register("otpStep")}
            defaultValue={userDetails.otpStep}
          />
        </div>
        <Button
          color={"white"}
          title={wLoading ? "Loading..." : "Activate Withdrawal"}
          navigate={activateWithdrawal}
        />
        <Button
          icon={
            loading && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Update User"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleSubmit((data) => updateUserProfitAndProfit(data))}
        />
      </div>
      <ToastContainer />
    </section>
  );
};

export default CreateFoodItems;
