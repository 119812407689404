import React, { useState } from "react";
import "./DashboardHeader.css";
import LoginImageHero from "../../asset/LogoText.svg";
import { RiMenu3Line } from "react-icons/ri";

const DashboardHeader = ({ toggleVisibility }) => {
  const [visibility, setVisibility] = useState(false);
  // const toggleVisible = () => {
  //   setVisibility(!visibility);
  //   console.log(visibility);
  // };
  return (
    <header className="dashboard_header">
      <img src={LoginImageHero} alt="" className="hero_login_image" />
      <RiMenu3Line className="harmburger" onClick={toggleVisibility} />
      <div className="profile">
        <h3 className="profile_text">CM</h3>
      </div>
    </header>
  );
};

export default DashboardHeader;
